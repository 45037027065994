.mc-register {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.mc-register-form {
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
}

.mc-register-form-wrapper {
  margin: auto 0;
}

.mc-icon-field div,
.mc-label-field-group div,
.mc-label-field-group div input {
  width: 100% !important;
}
.mc-label-field-select {
  width: 100% !important;
}

.mc-header-search {
  width: 350px;
}

.mc-header-search div {
  width: 100%;
}

.tree-container {
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  background-color: red;
}

.mc-table-head tr th,
.mc-table-body tr td {
  max-width: 250px !important;
  text-overflow: unset !important;
  word-break: break-all !important;
  white-space: unset !important;
}

.ib-render-box {
  padding: 16px;
  border: 1px solid grey;
  margin-bottom: 15px;
  display: inline-flex !important;
}

.ib-render-box ul {
  margin-left: 40px;
}

@media only screen and (max-width: 700px) {
  .mc-table-responsive th {
    min-width: 150px !important;
  }
}

.chakra-tabs button {
  padding: 8px !important;
}

.reactSelect__control {
  background: var(--lightGray) !important;
  padding: 0px 8px;
  border-radius: 8px !important;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid var(--deepBorder) !important;
}

.reactSelect__value-container {
  min-height: 42px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* .mc-header {
  padding-top: 0px !important;
}

.mc-main {
  padding-top: 45px !important;
} */

.termsHTML ol {
  list-style: decimal !important;
}

.termsHTML ul {
  list-style: disc !important;
}

.pagination {
  display: flex;
  flex-direction: row;
}

.pagination li {
  height: 35px;
  width: 35px;
  background: var(--lightGray);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-weight: 500;
}

.pagination .active {
  background-color: #1e50ff;
  border-radius: 50%;
}

.pagination .selected {
  background: var(--primaryHEX) !important;
  color: white !important;
}

.mc-header-dropdown-list {
  height: 100% !important;
  max-height: 300px !important;
}
